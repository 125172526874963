import styled from 'styled-components';
import ButtonBase from '../../button';
import { ButtonProps } from '../../button/base';

const Reset = styled(ButtonBase)<ButtonProps>``;

Reset.defaultProps = {
  as: 'input',
  type: 'reset',
  variant: 'secondary',
  value: 'Reset',
};

export default Reset;
