import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import Button from '../../button';
import { Flex } from '../../flex';
import Label from '../label';
import HelpText from '../helpText';
import Legend from '../legend';

interface FormGroupProps {
  as?: string; //FIXME
}

const FormGroup = styled(Flex)<FormGroupProps>`
  :disabled {
    *:not(${Button}) {
      color: ${themeGet('colors.contrast.50')};
      // opacity: 0.5;
    }
  }

  > :first-child:not(${Label}) {
    margin-top: 2rem; // FIXME: This should be a variable
  }

  > :last-child:not(${HelpText}) {
    margin-bottom: 1.5rem; // FIXME: This should be a variable
  }

  + ${Legend} {
    margin-top: ${themeGet('space.full')};
  }
`;

FormGroup.defaultProps = {
  as: 'fieldset',
  pb: 'full',
};

export default FormGroup;
