import styled from 'styled-components';
import { display, DisplayProps, overflow, OverflowProps } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

import LAYOUT, { LayoutStyle } from '../styleProps/layout';
import COMMON, { CommonStyle } from '../styleProps/common';
import POSITION, { PositionStyle } from '../styleProps/position';
import BORDER, { BorderStyle } from '../styleProps/border';
import BACKGROUND, { BackgroundStyle } from '../styleProps/background';

export interface SurfaceProps
  extends LayoutStyle,
    CommonStyle,
    PositionStyle,
    BorderStyle,
    BackgroundStyle,
    DisplayProps,
    OverflowProps {
  cursor?: string;
}

/**
 * @name: Surface
 * @description: This is a generic surface component based on the <Box /> component.
 * This component should be used for anything that needs a background, color or borders.
 */
const Surface = styled.div<SurfaceProps>`
  box-sizing: border-box;
  display:block;
  line-height: ${p => themeGet('lineHeights.base', '1.5')(p)};
  cursor: ${p => p.cursor || 'default'};

  ${COMMON}
  ${LAYOUT}
  ${POSITION}
  ${display}
  ${overflow}
  ${BORDER}
  ${BACKGROUND}
`;

Surface.defaultProps = {
  width: 'auto',
};

export default Surface;
