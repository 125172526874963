import {
  compose,
  width,
  WidthProps,
  display,
  DisplayProps,
  maxWidth,
  MaxWidthProps,
  minWidth,
  MinWidthProps,
  height,
  HeightProps,
  maxHeight,
  MaxHeightProps,
  minHeight,
  MinHeightProps,
  overflow,
  OverflowProps,
  verticalAlign,
  VerticalAlignProps,
  size,
  SizeProps,
} from 'styled-system';

export interface LayoutStyle
  extends WidthProps,
    DisplayProps,
    MaxHeightProps,
    MinWidthProps,
    HeightProps,
    MaxWidthProps,
    MinHeightProps,
    OverflowProps,
    VerticalAlignProps,
    SizeProps {}

const LAYOUT = compose(
  display,
  size,
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  overflow,
  verticalAlign
);

export default LAYOUT;
