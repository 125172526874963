const SM = '40rem';
const MD = '52rem';
const LG = '64rem';
const XL = '80rem';

const breakpoints = [SM, MD, LG, XL];

// aliases
breakpoints.sm = SM;
breakpoints.md = MD;
breakpoints.lg = LG;
breakpoints.xl = XL;

export default breakpoints;
