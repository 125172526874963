import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import Surface, { SurfaceProps } from '../surface';
import POSITION, { PositionStyle } from '../styleProps/position';

interface SectionProps extends PositionStyle, SurfaceProps {}

const Section = styled(Surface)<SectionProps>`
  :last-child {
    border-bottom-left-radius: ${themeGet('radii.default')};
    border-bottom-right-radius: ${themeGet('radii.default')};
  }

  ${POSITION}
`;

Section.defaultProps = {
  bg: 'white',
  borderRadius: 'default',
  boxShadow: 'default',
  position: 'relative',
  border: 'default',
  mb: 'full',
};

export default Section;
