import React from 'react';

import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { Text } from '../typography';
import { Flex } from '../flex';
import makeVariant from './variants';
import Icon from './icon';

const Item = styled.div`
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
`;

interface ContentProps {
  variant?: string;
}

const Content = styled(Flex)<ContentProps>`
  padding: ${p => themeGet('space.full')(p)};
  margin: ${p => themeGet('space.full')(p)};
  margin-top: 0;

  max-width: 40rem;
  min-width: 40vw;

  box-shadow: 0 3px 10px 0 rgba(35, 32, 32, 0.35);

  border-radius: ${p => themeGet('radii.default')(p)};

  ${p => makeVariant(p)}
`;

function Toast({ variant, title, text, ...rest }) {
  return (
    <Item {...rest}>
      <Content alignItems="center" variant={variant}>
        <Icon width={24} height={24} variant={variant} />

        <Flex mr="full">
          {title && <Text mr="third">{title}</Text>}
          {text && <Text>{text}</Text>}
        </Flex>
      </Content>
    </Item>
  );
}

export default Toast;
