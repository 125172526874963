import styled from 'styled-components';

import BORDER, { BorderStyle } from '../styleProps/border';
import Box, { BoxProps } from '../box';

interface SectionBodyProps extends BorderStyle, BoxProps {
  as?: string; // FIXME
}

const Body = styled(Box)<SectionBodyProps>`
  ${BORDER}
`;

Body.defaultProps = {
  as: 'div',
  p: 'large',
  pt: 'full',
  display: 'block',
};

export default Body;
