import styled from 'styled-components';
import { display, DisplayProps, overflow, OverflowProps } from 'styled-system';

import LAYOUT, { LayoutStyle } from '../styleProps/layout';
import COMMON, { CommonStyle } from '../styleProps/common';
import POSITION, { PositionStyle } from '../styleProps/position';

import breakpoints from '../theme/breakpoints';

interface ContainerProps
  extends LayoutStyle,
    CommonStyle,
    PositionStyle,
    DisplayProps,
    OverflowProps {
  fixed?: boolean;
}

const maxWidths = {
  _: '100%',
  sm: breakpoints['sm'],
  md: breakpoints['md'],
  lg: breakpoints['lg'],
  xl: breakpoints['xl'],
};

const Container = styled.div.attrs<ContainerProps>(({ fixed }) => ({
  maxWidth: fixed ? maxWidths : '100%',
}))`
    box-sizing: border-box;
    display:block;
    width: 100%;
    margin: 0 auto;

    ${COMMON}
    ${LAYOUT}
    ${POSITION}
    ${display}
    ${overflow}
`;

export default Container;
