import React from 'react';
import Container from './container';

function Icon({ fill, ...rest }) {
  return (
    <Container fill={fill} viewBox="0 0 32 32" {...rest}>
      <path
        id="Path_1099"
        d="M27.314 4.686a16 16 0 1 0 0 22.628 16 16 0 0 0 0-22.628zm-9.228 18.271a2.087 2.087 0 0 1-4.174 0v-8.348a2.087 2.087 0 0 1 4.174 0zm-2.123-11.916a1.861 1.861 0 0 1-1.978-1.9 1.86 1.86 0 0 1 2-1.928 1.919 1.919 0 1 1-.024 3.83z"
      />
    </Container>
  );
}

export default Icon;
