import {
  compose,
  position,
  PositionProps,
  zIndex,
  ZIndexProps,
  top,
  TopProps,
  right,
  RightProps,
  bottom,
  BottomProps,
  left,
  LeftProps,
} from 'styled-system';

export interface PositionStyle
  extends PositionProps,
    ZIndexProps,
    TopProps,
    RightProps,
    BottomProps,
    LeftProps {}

const POSITION = compose(position, zIndex, top, right, bottom, left);

export default POSITION;
