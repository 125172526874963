import { themeGet } from '@styled-system/theme-get';

const variants = {
  default: props => ({
    background: themeGet('colors.contrast.90')(props),
    color: themeGet('colors.contrast.05')(props),
  }),
  warning: props => ({
    background: themeGet('colors.contrast.90')(props),
    color: themeGet('colors.warning.40')(props),
  }),
  success: props => ({
    background: themeGet('colors.contrast.90')(props),
    color: themeGet('colors.success.40')(props),
  }),
  error: props => ({
    background: themeGet('colors.contrast.90')(props),
    color: themeGet('colors.error.40')(props),
  }),
};

function makeVariant(props) {
  const { variant } = props;

  const getVariant = (variants[variant] || variants['default'])(props);

  const css = `
    background-color: ${getVariant.background};
    color: ${getVariant.color};

    svg {
      fill: ${getVariant.color};
      height:auto;
      max-width:100%;
    }
  `;

  return css;
}

export default makeVariant;
