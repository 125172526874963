import styled from 'styled-components';

import { H5 } from '../../typography';

const Legend = styled(H5)`
  font-weight: bold;
  margin-bottom: 1rem;
`;

Legend.defaultProps = {
  color: 'brand.primary',
};

export default Legend;
