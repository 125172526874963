import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import COMMON, { CommonStyle } from '../styleProps/common';
import TYPOGRAPHY, { TypographyStyle } from '../styleProps/typography';

import { ellipsis } from './utils';

export interface TextProps extends CommonStyle, TypographyStyle {
  block?: boolean;
  muted?: boolean;
  small?: boolean;
  truncate?: boolean;
}

const Text = styled.span<TextProps>`
  display: ${({ block }) => (block ? 'block' : 'inline-block')};
  opacity: ${p => (p.muted ? '0.75' : '1')};
  font-size: ${p => p.small && '90%'};
  line-height: ${themeGet('lineHeights.base')}rem;
  font-family: 'Open Sans', sans-serif;

  ${({ truncate }) => truncate && ellipsis}
  
  ${COMMON}
  ${TYPOGRAPHY}
`;

export default Text;
