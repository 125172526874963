import styled from 'styled-components';

import { Flex } from '../../flex';
import Button from '../../button';

interface FormActionsProps {
  as?: string; //FIXME
}

const FormActions = styled(Flex)<FormActionsProps>`
  justify-content: flex-end;

  ${Button}, input {
    margin-right: 0.5rem;
  }

  ${Button}:last-child, input:last-child {
    margin-right: 0rem;
  }
`;

FormActions.defaultProps = {
  as: 'footer',
  flexDirection: 'row',
};

export default FormActions;
