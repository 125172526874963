import colors from './colors';
import typography from './typography';
import breakpoints from './breakpoints';

const borders = {
  default: `1px solid ${colors.contrast['30']}`,

  borderWidths: {
    default: '1px',
  },
  borderStyles: {
    default: 'solid',
  },
};

const BASE = 1;

// Spacing for padding and margins.
const space = {
  base: BASE,
  quarter: `${BASE * 0.25}rem`,
  third: `${BASE / 3}rem`,
  half: `${BASE * 0.5}rem`,
  full: `${BASE}rem`,
  large: `${BASE * 1.5}rem`,
  xlarge: `${BASE * 2}rem`,
};

const shadows = {
  none: 'none',
  default: `
    0 1px 1px rgba(0,0,0,0.01), 
    0 2px 2px rgba(0,0,0,0.01), 
    0 4px 4px rgba(0,0,0,0.01), 
    0 8px 8px rgba(0,0,0,0.01),
    0 16px 16px rgba(0,0,0,0.01);
  `,
  large: `
    0 1px 1px rgba(0,0,0,0.05), 
    0 2px 2px rgba(0,0,0,0.05), 
    0 4px 4px rgba(0,0,0,0.05), 
    0 8px 8px rgba(0,0,0,0.05), 
    0 16px 16px rgba(0,0,0,0.05), 
    0 32px 32px rgba(0,0,0,0.05);
  `,
};

const radii = {
  default: '4px',
  lg: '10px',
  round: '100%',
};

const buttons = {
  padding: {
    x: space.half,
    y: space.full,
  },
};

const forms = {
  background: colors.white,
  padding: {
    x: space.half,
    y: space.full,
  },
};

const theme = {
  colors,
  space,
  shadows,
  radii,
  ...typography,
  buttons,
  forms,
  borders,
  breakpoints,
};

export default theme;
