import styled from 'styled-components';

import Box, { BoxProps } from '../box';
import COMMON from '../styleProps/common';

interface ListProps extends BoxProps {}

const List = styled(Box)<ListProps>`
  list-style: none;

  ${COMMON}
`;

List.defaultProps = {
  as: 'ul',
  m: 0,
  p: 0,
};

export default List;
