import { themeGet } from '@styled-system/theme-get';
import { darken, transparentize } from 'polished';

// Define all variants here:
// A map containing color data about each different button variant.
// Each type (i.e primary) has a method that will return the data.
const getVariants = props => ({
  primary: {
    background: themeGet('colors.contrast.90')(props),
    color: themeGet('colors.white')(props),
    border: themeGet('colors.contrast.90')(props),
    hover: {
      background: themeGet('colors.primary.60')(props),
      color: themeGet('colors.white')(props),
      border: themeGet('colors.primary.60')(props),
    },
    active: {
      background: themeGet('colors.contrast.90')(props),
      color: themeGet('colors.white')(props),
    },
    disabled: {
      background: themeGet('colors.contrast.60')(props),
      color: themeGet('colors.contrast.30')(props),
      border: themeGet('colors.contrast.60')(props),
    },
  },
  default: {
    background: themeGet('colors.contrast.05')(props),
    color: themeGet('colors.contrast.70')(props),
    border: themeGet('colors.contrast.70')(props),
    hover: {
      background: themeGet('colors.primary.05')(props),
      color: themeGet('colors.primary.70')(props),
      border: themeGet('colors.primary.40')(props),
    },
    active: {
      color: themeGet('colors.contrast.70')(props),
    },
    disabled: {
      background: themeGet('colors.contrast.05')(props),
      color: themeGet('colors.contrast.50')(props),
      border: themeGet('colors.contrast.20')(props),
    },
  },
  transparent: {
    background: themeGet('colors.transparent')(props),
    color: themeGet('colors.text')(props),
    border: themeGet('colors.transparent')(props),
    hover: {
      background: themeGet('colors.transparent')(props),
      border: themeGet('colors.transparent')(props),
    },
    active: {
      color: themeGet('colors.text')(props),
    },
    disabled: {
      background: themeGet('colors.transparent')(props),
      color: themeGet('colors.text')(props),
      border: themeGet('colors.transparent')(props),
    },
  },
});

function variantValues({ variants, variant, type }) {
  const getVariant = variants[variant] || variants['default'];
  const variantType = getVariant[type] || getVariant;

  const background =
    variantType.background || darken(0.025, getVariant.background);

  const color = variantType.color || darken(0.05, getVariant.color);

  const border = variantType.border || darken(0.025, getVariant.border);

  const shadow = variantType.border
    ? transparentize(0.7, variantType.border)
    : transparentize(0.8, getVariant.border);

  return {
    background,
    color,
    border,
    shadow,
  };
}

// This function will take the color data and return css
// for the button variant
function getButtonVariant(props) {
  const { variant, disabled, toggled } = props;

  const variants = getVariants(props);

  const { background, color, border } = variantValues({
    variants,
    variant,
    type: undefined,
  });

  const borderColor = border || darken(0.05, background);

  // Get color values for the different variants.
  const hoverState = variantValues({ variants, variant, type: 'hover' });
  const activeState = variantValues({ variants, variant, type: 'active' });

  const hoverVariant = `
    background-color: ${hoverState.background};
    color: ${hoverState.color};
    border-color: ${hoverState.border};

    :not(:focus) {
      box-shadow: 0 5px 9px ${hoverState.shadow};
    }
  `;

  const activeVariant = `
    background-color: ${activeState.background};
    border-color: ${activeState.border};
    color: ${activeState.color};
  `;

  return `
    background-color: ${background};
    color: ${color};
    border-color: ${borderColor};

    &:hover {
      ${!disabled && hoverVariant}
    }

    &:active {
      ${!disabled && activeVariant}
    }

    ${toggled && hoverVariant}

  `;
}

export default getButtonVariant;
