import { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const CSSGlobal = css`
  html,
  body {
    width: 100%;
    height: 100%;
  }

  body {
    background: ${themeGet('colors.body')};

    font-family: 'Open Sans', sans-serif;
    font-size: ${themeGet('fontSizes.base')};
    line-height: ${themeGet('lineHeights.base')};
    color: ${themeGet('colors.text')};
  }

  *:focus {
    outline: solid;
    outline-color: ${themeGet('colors.brand.primary')};
    outline-width: thin;
  }

  a {
    color: ${themeGet('colors.brand.primary')};

    &:hover {
      color: ${themeGet('colors.primary.80')};
    }
  }

  b,
  strong {
    font-weight: 800;
  }
`;

export default CSSGlobal;
