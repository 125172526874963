import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { transparentize } from 'polished';

import COMMON, { CommonStyle } from '../styleProps/common';
import LAYOUT, { LayoutStyle } from '../styleProps/layout';
import FLEX, { FlexContainerStyle } from '../styleProps/flexContainer';
import BACKGROUND, { BackgroundStyle } from '../styleProps/background';
import POSITION, { PositionStyle } from '../styleProps/position';

interface BackdropProps
  extends CommonStyle,
    LayoutStyle,
    BackgroundStyle,
    PositionStyle,
    FlexContainerStyle {}

// prettier-ignore
const Backdrop = styled.div<BackdropProps>`
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  
  width:100vw;
  height:100vh;

  background:${p => transparentize(0.1, themeGet("colors.primary.80")(p))};

  ${FLEX}
  ${COMMON}
  ${BACKGROUND}
  ${LAYOUT}
  ${POSITION}
`;

Backdrop.defaultProps = {
  justifyContent: 'center',
  alignItems: 'center',
};

export default Backdrop;
