import { themeGet } from '@styled-system/theme-get';

// NOTE: This should probably be used for the inputs as well.
const sizesMap = p => ({
  sm: {
    padding: '.375rem .5rem',
    fontSize: themeGet('fontSizes.sm')(p),
    height: 'auto',
  },
  base: {
    padding: '.5rem 1rem',
    fontSize: themeGet('fontSizes.base')(p),
    height: `calc(${themeGet('lineHeights.base')(p)}rem + ${themeGet(
      'forms.padding.y'
    )(p)} + (${themeGet('borders.borderWidths.default')(p)} * 2));`,
  },
});

function getButtonSize(props) {
  const { size } = props;
  const sizes = sizesMap(props);
  const { fontSize, padding, height } = sizes[size] || sizes['base'];

  return `
    font-size: ${fontSize};
    padding: ${padding};
    height: ${height}
  `;
}

export default getButtonSize;
