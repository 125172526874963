import styled from 'styled-components';
import Box, { BoxProps } from '../box';

interface ListItemProps extends BoxProps {}

const ListItem = styled(Box)<ListItemProps>``;

ListItem.defaultProps = {
  as: 'li',
  px: 'full',
  py: 'half',
};

export default ListItem;
