import styled from 'styled-components';
import { display, DisplayProps, overflow, OverflowProps } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

import TYPOGRAPHY, { TypographyStyle } from '../styleProps/typography';
import LAYOUT, { LayoutStyle } from '../styleProps/layout';
import COMMON, { CommonStyle } from '../styleProps/common';
import POSITION, { PositionStyle } from '../styleProps/position';
import BORDER, { BorderStyle } from '../styleProps/border';

import variants from './variants';

interface BadgeProps
  extends TypographyStyle,
    LayoutStyle,
    CommonStyle,
    PositionStyle,
    BorderStyle,
    DisplayProps,
    OverflowProps {
  variant?: string;
}

// prettier-ignore
const Badge = styled('span')<BadgeProps>`
  display: inline-block;
  background: ${p => themeGet(`colors.${variants[p.variant || "default"].bg}`)(p)};
  color: ${p => themeGet(`colors.${variants[p.variant || "default"].color}`)(p)};
  border-radius: ${p => themeGet(p.borderRadius || "radii.default")(p)};

  font-size: 85%
  line-height: ${p => themeGet(p.lineHeight,"lineHeights.base")(p)};
  height: ${p => themeGet(p.lineHeight,"lineHeights.base")(p)};

  ${BORDER}
  ${COMMON}
  ${TYPOGRAPHY}
  ${LAYOUT}
  ${POSITION}
  ${display}
  ${overflow};
`;

Badge.defaultProps = {
  borderRadius: 'default',
  px: 'half',
};

export default Badge;
