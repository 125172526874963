import styled from 'styled-components';
import { overflow, OverflowProps } from 'styled-system';

import COMMON, { CommonStyle } from '../styleProps/common';
import FLEX_CONTAINER, {
  FlexContainerStyle,
} from '../styleProps/flexContainer';
import LAYOUT, { LayoutStyle } from '../styleProps/layout';

export interface FlexProps
  extends CommonStyle,
    FlexContainerStyle,
    LayoutStyle,
    OverflowProps {}

const FlexContainer = styled.div<FlexProps>`
  display: flex;

  ${COMMON}
  ${FLEX_CONTAINER}
  ${LAYOUT}
  ${overflow}
`;

export default FlexContainer;
