import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';

import Flex, { FlexProps } from '../flex/container';
import { Container } from '../layout';

import BORDER, { BorderStyle } from '../styleProps/border';
import BACKGROUND, { BackgroundStyle } from '../styleProps/background';

interface AppBarProps extends FlexProps, BorderStyle, BackgroundStyle {
  as?: string;
}

const AppBar = styled(Flex)<AppBarProps>`
  position: relative;
  align-items: center;

  height: 72px; // FIXME!!

  ${Container} {
    display: flex;
    align-items: center;
  }

  padding: 0 ${p => themeGet('space.full')(p)};

  ${BORDER}
  ${BACKGROUND}
`;

AppBar.defaultProps = {
  as: 'header',
  bg: 'white',
  boxShadow: 'default',
};

export default AppBar;
