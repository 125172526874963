// Constants
const FONT_SIZE_BASE = 1;

const fonts = {
  'san-serif': `'Open Sans', sans-serif`,
  monospace: `SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`,
  base: `'Open Sans', sans-serif`,
};

const fontSizes = {
  sm: `${FONT_SIZE_BASE * 0.875}rem`,
  base: `${FONT_SIZE_BASE}rem`,
  lg: `${FONT_SIZE_BASE * 1.25}rem`,

  h1: `${FONT_SIZE_BASE * 4}rem`,
  h2: `${FONT_SIZE_BASE * 3}rem`,
  h3: `${FONT_SIZE_BASE * 2.25}rem`,
  h4: `${FONT_SIZE_BASE * 1.75}rem`,
  h5: `${FONT_SIZE_BASE * 1.25}rem`,
  h6: `${FONT_SIZE_BASE}rem`,
};

const fontWeights = {
  normal: 400,
  bold: 600,
  base: 400,
};

const lineHeights = {
  base: 1.5,
};

const typography = {
  fonts,
  lineHeights,
  fontSizes,
  fontWeights,
};

export default typography;
