import styled from 'styled-components';

import COMMON, { CommonStyle } from '../../styleProps/common';

interface FormInterface extends CommonStyle {}

const Form = styled('form')<FormInterface>`
  position: relative;
  ${COMMON};
`;

export default Form;
