import React from 'react';

import Surface from '../surface';

function SectionFooter({ children, ...rest }): JSX.Element {
  return (
    <Surface
      as="footer"
      p="full"
      bg="contrast.05"
      borderTop="default"
      {...rest}
    >
      {children}
    </Surface>
  );
}

export default SectionFooter;
