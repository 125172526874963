import { themeGet } from '@styled-system/theme-get';
import { darken } from 'polished';

import AlertTitle from './title';
import AlertText from './text';

const variants = {
  info: props => ({
    background: themeGet('colors.primary.05')(props),
    color: themeGet('colors.primary.80')(props),
    borderColor: themeGet('colors.primary.20')(props),
  }),
  warning: props => ({
    background: themeGet('colors.warning.05')(props),
    color: themeGet('colors.contrast.80')(props),
    borderColor: themeGet('colors.warning.20')(props),
  }),
  success: props => ({
    background: themeGet('colors.success.05')(props),
    color: themeGet('colors.success.80')(props),
    borderColor: themeGet('colors.success.20')(props),
  }),
  error: props => ({
    background: themeGet('colors.error.05')(props),
    color: themeGet('colors.error.70')(props),
    borderColor: themeGet('colors.error.20')(props),
  }),
  default: props => ({
    background: themeGet('colors.contrast.10')(props),
    color: themeGet('colors.contrast.80')(props),
    borderColor: themeGet('colors.contrast.30')(props),
  }),
};

function makeVariant(props) {
  const { variant } = props;

  const getVariant = (variants[variant] || variants['default'])(props);

  return `
    position: relative;
    background: ${getVariant.background};
    
    color: ${getVariant.color};

    border-radius: ${themeGet('radii.default')(props)};

    ${AlertTitle} {
      color: ${getVariant.color};
    }
    
    ${AlertText} {
      border-top-color:${getVariant.borderColor};
    }

    ::before,
    ::after {
      display:block;
      position: absolute;
      content: " ";
      background: ${getVariant.borderColor};
      height:100%;
      width:4px;
      
      top:0;
    }

    ::before {
      border-top-left-radius: ${themeGet('radii.default')(props)};
      border-bottom-left-radius: ${themeGet('radii.default')(props)};
      left:0;
    }

    ::after {
      border-top-right-radius: ${themeGet('radii.default')(props)};
      border-bottom-right-radius: ${themeGet('radii.default')(props)};
      right:0;
    }

    a {
      color: ${getVariant.color};
      text-decoration: underline;
    }

    svg {
      fill: ${darken(0.2, getVariant.borderColor)};
    }

    a:hover {
      color: ${darken(0.2, getVariant.color)};
    }
  `;
}

export default makeVariant;
