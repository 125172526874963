import {
  compose,
  flex,
  FlexProps,
  justifySelf,
  JustifySelfProps,
  alignSelf,
  AlignSelfProps,
} from 'styled-system';

const FLEX_ITEM = compose(flex, justifySelf, alignSelf);

export interface FlexItemStyle
  extends FlexProps,
    JustifySelfProps,
    AlignSelfProps {}

export default FLEX_ITEM;
