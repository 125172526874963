import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import FLEX_CONTAINER, {
  FlexContainerStyle,
} from '../styleProps/flexContainer';
import Surface from '../surface';
import { FlexItem } from '../flex';

import Actions from './headerActions';

interface SectionHeaderWrapperProps extends FlexContainerStyle {
  as?: string; // FIXME!!
}

const Wrapper = styled(Surface)<SectionHeaderWrapperProps>`
  min-height: calc(
    ${themeGet('lineHeights.base')}rem + ${themeGet('forms.padding.y')} +
      (${themeGet('borders.borderWidths.default')} * 2)
  );

  box-sizing: content-box;

  ${FLEX_CONTAINER}
`;

Wrapper.defaultProps = {
  as: 'header',
  p: 'large',
  pb: 'full',
  display: 'flex',
  alignItems: 'center',
};

interface SectionHeaderProps {
  icon?: ReactNode;
  title?: ReactNode;
  actions?: ReactNode;
}

const SectionHeader: FunctionComponent<SectionHeaderProps> = ({
  icon,
  title,
  actions,
  children,
  ...rest
}) => {
  return (
    <Wrapper data-testid="section-header" {...rest}>
      {icon && <FlexItem mr="full">{icon}</FlexItem>}
      {title && <FlexItem>{title}</FlexItem>}
      {children && <FlexItem>{children}</FlexItem>}
      <Actions actions={actions} />
    </Wrapper>
  );
};

export default SectionHeader;
