import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import COMMON, { CommonStyle } from '../styleProps/common';
import TYPOGRAPHY, { TypographyStyle } from '../styleProps/typography';

export interface HeadingProps extends CommonStyle, TypographyStyle {}

const headingsBase = css`
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 200;
  line-height: ${themeGet('lineHeights.base')}rem;
  letter-spacing: -0.22px;
  color: ${themeGet('colors.contrast.80')};
`;

const H1 = styled.h1<HeadingProps>`
  ${headingsBase}
  font-size: ${themeGet('fontSizes.h1')};
  line-height: ${themeGet('fontSizes.h1')};
  ${COMMON}
  ${TYPOGRAPHY}
`;

const H2 = styled.h2<HeadingProps>`
  ${headingsBase}
  font-size: ${themeGet('fontSizes.h2')};
  line-height: ${themeGet('fontSizes.h2')};
  ${COMMON}
  ${TYPOGRAPHY}
`;

const H3 = styled.h3<HeadingProps>`
  ${headingsBase}
  font-weight:400;
  font-size: ${themeGet('fontSizes.h3')};
  line-height: ${themeGet('fontSizes.h3')};
  ${COMMON}
  ${TYPOGRAPHY}
`;

const H4 = styled.h4<HeadingProps>`
  ${headingsBase}
  font-size: ${themeGet('fontSizes.h4')};
  line-height:${themeGet('fontSizes.h4')};
  ${COMMON}
  ${TYPOGRAPHY}
`;

const H5 = styled.h5<HeadingProps>`
  ${headingsBase}
  font-weight:400;
  font-size: ${themeGet('fontSizes.h5')};
  line-height: ${themeGet('fontSizes.h5')};
  ${COMMON}
  ${TYPOGRAPHY}
`;

const H6 = styled.h6<HeadingProps>`
  ${headingsBase}
  font-weight:400;
  font-size: ${themeGet('fontSizes.h6')};
  line-height: ${themeGet('fontSizes.h6')};
  ${COMMON}
  ${TYPOGRAPHY}
`;

export { H1, H2, H3, H4, H5, H6 };
