import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { Flex } from '../flex';
import BORDER, { BorderStyle } from '../styleProps/border';
import BACKGROUND, { BackgroundStyle } from '../styleProps/background';

export interface CardProps extends BorderStyle, BackgroundStyle {}

const Card = styled(Flex)<CardProps>`
  background-color: ${p => themeGet('colors.white')(p)};

  > :first-child {
    border-top-left-radius ${p => themeGet('radii.default')(p)};
    border-top-right-radius: ${p => themeGet('radii.default')(p)};
  }

  > :last-child {
    border-bottom-left-radius ${p => themeGet('radii.default')(p)};
    border-bottom-right-radius: ${p => themeGet('radii.default')(p)};
  }

  ${BORDER}
  ${BACKGROUND}
`;

Card.defaultProps = {
  width: 'auto',
  border: 'default',
  borderRadius: 'default',
  flexDirection: 'column',
};

export default Card;
