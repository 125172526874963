import React from 'react';
import Container from './container';

function Icon({ fill, ...rest }) {
  return (
    <Container fill={fill} viewBox="0 0 32 32" {...rest}>
      <path
        id="waiting_ico"
        d="M16 0a16 16 0 1 0 16 16A15.987 15.987 0 0 0 16 0zm6.4 16.941H16a.889.889 0 0 1-.941-.941V6.4a.941.941 0 0 1 1.882 0v8.659H22.4a.941.941 0 1 1 0 1.882z"
      />
    </Container>
  );
}

export default Icon;
