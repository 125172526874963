import React from 'react';

import { Flex, FlexItem } from '../flex';
import Surface from '../surface';
import { H3 } from '../typography';

function Header({ title, children, ...rest }) {
  return (
    <Flex mb="full" {...rest}>
      <H3>{title}</H3>
      {children}
    </Flex>
  );
}

function Body({ children, ...rest }) {
  return <Flex {...rest}>{children}</Flex>;
}

function Footer({ children, ...rest }) {
  return (
    <Flex {...rest}>
      <FlexItem ml="auto">{children}</FlexItem>
    </Flex>
  );
}

function Modal({ children, ...rest }) {
  return (
    <Surface
      // tabIndex="1" FIXME!!
      border="default"
      boxShadow="default"
      borderRadius="default"
      bg="white"
      p="full"
      {...rest}
    >
      <Flex flexDirection="column">{children}</Flex>
    </Surface>
  );
}

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
