import styled from 'styled-components';

import inputBase, { InputBaseProps } from '../base';

interface InputProps extends InputBaseProps {
  as?: string; //fixme
}

const Input = styled('input')<InputProps>`
  ${inputBase}
`;

Input.defaultProps = {
  as: 'input',
  type: 'text',
};

export default Input;
