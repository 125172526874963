import React from 'react';
import Container from './container';

function Icon({ fill, ...rest }) {
  return (
    <Container fill={fill} viewBox="0 0 20 20" {...rest}>
      <path
        id="success_ico"
        d="M10 0A9.943 9.943 0 0 0 0 10a9.943 9.943 0 0 0 10 10 10 10 0 0 0 0-20zm4.2 6.136L10 14.659a.934.934 0 0 1-.682.455.767.767 0 0 1-.682-.455l-2.955-5.8a.883.883 0 0 1 .341-1.136.883.883 0 0 1 1.136.341l2.159 4.2 3.523-7.045a.788.788 0 0 1 1.136-.341 1.037 1.037 0 0 1 .224 1.258z"
      />
    </Container>
  );
}

export default Icon;
