import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const IconWrapper = styled.svg.attrs(({ width, height }) => ({
  width,
  height,
}))`
  display: inline-block;
  max-width: 100%;
  height: auto;
  fill: ${p => themeGet(`colors.${p.fill}`, '#fff')};
`;

export default IconWrapper;
