import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import defaultTheme from './theme';

import reset from './reset';
import global from './global';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
  
  ${reset}
  ${global}

`;

interface UITheme {
  children: JSX.Element;
  theme?: object;
  globalStyles?: Boolean;
}

function Theme({
  children,
  theme,
  globalStyles = true,
  ...rest
}: UITheme): JSX.Element {
  return (
    <ThemeProvider theme={theme || defaultTheme} {...rest}>
      <React.Fragment>
        {globalStyles && <GlobalStyle />}
        {children}
      </React.Fragment>
    </ThemeProvider>
  );
}

export default Theme;
