import {
  compose,
  fontFamily,
  FontFamilyProps,
  fontSize,
  FontSizeProps,
  fontStyle,
  FontStyleProps,
  fontWeight,
  FontWeightProps,
  lineHeight,
  LineHeightProps,
  textAlign,
  TextAlignProps,
} from 'styled-system';

export interface TypographyStyle
  extends FontFamilyProps,
    FontSizeProps,
    FontStyleProps,
    FontWeightProps,
    LineHeightProps,
    TextAlignProps {}

const TYPOGRAPHY = compose(
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  lineHeight,
  textAlign
);

export default TYPOGRAPHY;
