import styled from 'styled-components';
import { display, DisplayProps } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

import Text, { TextProps } from '../../typography/text';

interface HelpTextProps extends TextProps, DisplayProps {
  error?: boolean;
}

const HelpText = styled(Text)<HelpTextProps>`
  color: ${p =>
    p.color || themeGet(`colors.${p.error ? 'error.90' : 'contrast.60'}`)(p)};
  display: block;
  line-height: 1;
  ${display}
`;

HelpText.defaultProps = {
  small: true,
  pt: 'half',
  display: 'block',
};

export default HelpText;
