import styled, { keyframes } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export interface SpinnerProps {
  size?: string;
}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const PRIMARY_COLOR = 'colors.primary.80';
const SECONDARY_COLOR = 'colors.white';

const Spinner = styled.div<SpinnerProps>`
  animation: ${rotate360} 0.75s linear infinite;

  border: 2px solid ${themeGet(SECONDARY_COLOR)};
  border-left: 4px solid ${themeGet(PRIMARY_COLOR)};
  background: transparent;
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 50%;
`;

Spinner.defaultProps = {
  size: '24px',
};

export default Spinner;
