import styled from 'styled-components';

import { Flex } from '../flex';

const Content = styled(Flex)`
  width: 100%;
`;

Content.defaultProps = {
  px: 'full',
  py: 'half',
};

export default Content;
