import React from 'react';

function SvgComponent(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M0 0h24v24H0z"
        className="prefix__cls-1"
        data-name="Rectangle 811"
      />
      <path
        d="M4.71 9.478l6.713 6.71a.743.743 0 00.055.081.757.757 0 001.044 0 .738.738 0 00.055-.081l6.713-6.71a.72.72 0 00-1.018-1.018L12 14.732 5.727 8.46A.72.72 0 104.71 9.478z"
        className="prefix__cls-2"
        data-name="Path 1850"
      />
    </svg>
  );
}

export default SvgComponent;
