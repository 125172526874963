import styled from 'styled-components';

import Box, { BoxProps } from '../box';

import COMMON, { CommonStyle } from '../styleProps/common';
import BORDER, { BorderStyle } from '../styleProps/border';

import makeVariant from './variants';

interface AlertBoxProps extends CommonStyle, BorderStyle, BoxProps {
  variant?: string;
}

const AlertBox = styled(Box)<AlertBoxProps>`
  ${p => makeVariant(p)}

  ${COMMON}
  ${BORDER}
`;

AlertBox.defaultProps = {
  px: 'full',
  py: 'half',
  width: '100%',
};

export default AlertBox;
