import React from 'react';
import Container from './container';

function Icon({ fill, ...rest }) {
  return (
    <Container fill={fill} viewBox="0 0 20 20" {...rest}>
      <path
        id="failure_ico"
        d="M2.926 17.075a10 10 0 1 0 0-14.149 10.016 10.016 0 0 0 0 14.149zM13.534 5.3A.822.822 0 1 1 14.7 6.467l-8.232 8.228a.822.822 0 1 1-1.162-1.162z"
      />
    </Container>
  );
}

export default Icon;
