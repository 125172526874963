import styled from 'styled-components';

import COMMON, { CommonStyle } from '../styleProps/common';
import FLEX_ITEM, { FlexItemStyle } from '../styleProps/flexItem';
import LAYOUT, { LayoutStyle } from '../styleProps/layout';

export interface FlexItemProps
  extends CommonStyle,
    FlexItemStyle,
    LayoutStyle {}

const FlexItem = styled.div<FlexItemProps>`
  ${COMMON}
  ${FLEX_ITEM}
  ${LAYOUT}
`;

FlexItem.defaultProps = {
  display: 'flex',
};

export default FlexItem;
