import styled from 'styled-components';
import { display, DisplayProps } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

import COMMON, { CommonStyle } from '../styleProps/common';
import BORDER, { BorderStyle } from '../styleProps/border';
import LAYOUT, { LayoutStyle } from '../styleProps/layout';

import { defaultTransition } from '../theme/utils';
import getButtonVariant from './variant';

import getButtonSize from './sizes';
import { ellipsis } from '../typography/utils';

export interface ButtonProps
  extends CommonStyle,
    BorderStyle,
    LayoutStyle,
    DisplayProps {
  variant?: string;
  block?: boolean;
  ellipsis?: boolean;
  disabled?: boolean;
  as?: string; // FIXME
}

/** Button base description here */
// prettier-ignore
const ButtonBase = styled('button')<ButtonProps>`
  text-decoration: none;
  text-align: center;
  font-weight: ${themeGet("fontWeights.normal")};
  vertical-align: middle;

  padding: ${themeGet("forms.padding.x")} ${themeGet("forms.padding.y")};
  line-height: 1;

  user-select: none;

  border: 1px solid transparent;
  background-color: transparent;
  border-radius: ${themeGet("radii.default")};
  text-decoration:none!important;

  transition: ${defaultTransition(0.15)};

  &:hover {
    text-decoration:none;
    z-index:2;
  }

  &:focus {
    outline:0;
    box-shadow: 0 0 0 2px ${p => themeGet('colors.warning.60')(p)};
  }

  ${p => getButtonSize(p)}

  ${p => getButtonVariant(p)}

  ${({block}) => block && `
    display:block;
    width:100%;
  `}

  cursor: ${({disabled}) => !disabled ? "pointer" : "default"};

  opacity: ${({disabled}) => disabled ? 0.65 : 1};

  * {
    cursor: ${({disabled}) => !disabled ? "pointer" : "default"};
  }

  ${p => p.ellipsis === true && ellipsis}

  ${COMMON}
  ${BORDER}
  ${LAYOUT}
  ${display}
`;

ButtonBase.displayName = 'ButtonBase';

ButtonBase.defaultProps = {
  display: 'inline-block',
  variant: 'default',
  type: 'button',
};

export default ButtonBase;
