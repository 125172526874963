import {
  compose,
  background,
  BackgroundProps,
  backgroundImage,
  BackgroundImageProps,
  backgroundSize,
  BackgroundSizeProps,
  backgroundPosition,
  BackgroundPositionProps,
  backgroundRepeat,
} from 'styled-system';

const BACKGROUND = compose(
  background,
  backgroundImage,
  backgroundSize,
  backgroundPosition,
  backgroundRepeat
);

export interface BackgroundStyle
  extends BackgroundProps,
    BackgroundImageProps,
    BackgroundSizeProps,
    BackgroundPositionProps {}

export default BACKGROUND;
