import styled from 'styled-components';
import { display, DisplayProps, overflow, OverflowProps } from 'styled-system';

import LAYOUT, { LayoutStyle } from '../styleProps/layout';
import COMMON, { CommonStyle } from '../styleProps/common';
import POSITION, { PositionStyle } from '../styleProps/position';
import { StyledSystemProps } from 'utils';

interface BoxStyleProps
  extends LayoutStyle,
    CommonStyle,
    PositionStyle,
    DisplayProps,
    OverflowProps {}

export interface BoxProps
  extends StyledSystemProps<'div', any>,
    BoxStyleProps {}

const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  display:block;

  ${COMMON}
  ${LAYOUT}
  ${POSITION}
  ${display}
  ${overflow}
`;

export default Box;
