import {
  GridGapProps,
  gridGap,
  gridColumnGap,
  GridColumnGapProps,
  gridRowGap,
  GridRowGapProps,
  gridColumn,
  GridColumnProps,
  gridRow,
  GridRowProps,
  gridAutoFlow,
  GridAutoFlowProps,
  gridAutoColumns,
  GridAutoColumnsProps,
  gridAutoRows,
  GridAutoRowsProps,
  gridTemplateColumns,
  GridTemplateColumnsProps,
  gridTemplateRows,
  GridTemplateRowsProps,
  gridTemplateAreas,
  GridTemplateAreasProps,
  gridArea,
  GridAreaProps,
} from 'styled-system';

import styled from 'styled-components';

interface GridProps
  extends GridGapProps,
    GridColumnGapProps,
    GridRowGapProps,
    GridColumnProps,
    GridRowProps,
    GridAutoFlowProps,
    GridAutoColumnsProps,
    GridAutoRowsProps,
    GridTemplateColumnsProps,
    GridTemplateRowsProps,
    GridTemplateAreasProps,
    GridAreaProps {}

const Grid = styled.div<GridProps>`
    display:grid;

    ${gridGap}
    ${gridColumnGap}
    ${gridRowGap}
    ${gridColumn}
    ${gridRow}
    ${gridAutoFlow}
    ${gridAutoColumns}
    ${gridAutoRows}
    ${gridTemplateColumns}
    ${gridTemplateRows}
    ${gridTemplateAreas}
    ${gridArea}
  `;

Grid.defaultProps = {
  gridGap: '10',
};

export default Grid;
