import React from 'react';

import Box from '../box';

import { WaitingIcon, InfoIcon, SuccessIcon, ErrorIcon } from '../icon';

const icons = {
  warning: WaitingIcon,
  info: InfoIcon,
  success: SuccessIcon,
  error: ErrorIcon,
  default: InfoIcon,
};

function ToastIcon({ variant, ...rest }) {
  const Icon = icons[variant] || icons['default'];

  if (!Icon) return null;

  return (
    <Box mr="full" height="1.6rem" width="1.6rem">
      <Icon {...rest} />
    </Box>
  );
}

export default ToastIcon;
