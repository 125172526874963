import {
  compose,
  borders,
  BordersProps,
  borderColor,
  BorderColorProps,
  boxShadow,
  BoxShadowProps,
  borderRadius,
  BorderRadiusProps,
} from 'styled-system';

const BORDER = compose(borders, borderColor, boxShadow, borderRadius);

export interface BorderStyle
  extends BordersProps,
    BorderColorProps,
    BoxShadowProps,
    BorderRadiusProps {}

export default BORDER;
