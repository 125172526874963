import styled, { css } from 'styled-components';
import { display, DisplayProps } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

import Text from '../../typography/text';
import TYPOGRAPHY, { TypographyStyle } from '../../styleProps/typography';
import COMMON, { CommonStyle } from '../../styleProps/common';

interface LabelProps extends TypographyStyle, CommonStyle, DisplayProps {
  required?: boolean;
  as?: string; //FIXME
}

const required = isRequired => {
  if (!isRequired) {
    return;
  }

  return css`
    :after {
      content: ' * ';
      color: ${themeGet('colors.brand.primary')};
    }
  `;
};

const Label = styled(Text)<LabelProps>`
  ${p => required(p.required)}

  font-weight:600;

  ${COMMON}
  ${TYPOGRAPHY}
  ${display}
`;

Label.defaultProps = {
  as: 'label',
  mb: 'half',
  display: 'block',
};

export default Label;
