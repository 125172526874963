import { themeGet } from '@styled-system/theme-get';
import { transparentize } from 'polished';

const makeGetVariant = props => ({
  default: {
    background: themeGet('forms.background')(props),
    color: themeGet('colors.contrast.60')(props),
    border: themeGet('colors.contrast.40')(props),
    shadow: themeGet('colors.warning.60')(props),
  },
  error: {
    background: themeGet('colors.error.05')(props),
    color: themeGet('colors.error.70')(props),
    border: themeGet('colors.error.30')(props),
    shadow: transparentize(0.5, themeGet('colors.error.60')(props)),
  },
});

function variant(props) {
  const { variant, error, disabled } = props;

  const colorVariant = makeGetVariant(props);

  const { background, color, border, shadow, backgroundImage } = error
    ? colorVariant['error']
    : colorVariant[variant];

  // prettier-ignore
  return `
    background: ${background};
    border: 1px solid ${border};
    color: ${color};

    ${!disabled && `
    background-image: url(${backgroundImage});
    background-position: calc(100% - ${themeGet('forms.padding.y')(props)}) center;
    background-repeat: no-repeat;
    `}

    :focus:not(:disabled) {
      border-color: ${color};
      box-shadow: 0 0 0 2px ${shadow};
    }

    &:disabled {
      background-color: rgba(0,0,0,0.025);
      border-color:transparent;
    }

    ::placeholder {
      color:${transparentize(0.5, color)};
    }

  `;
}

export default variant;
