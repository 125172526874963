interface BadgeVariants {
  [variant: string]: BadgeVariant;
}

interface BadgeVariant {
  bg: String;
  color: string;
}

const fallback: BadgeVariant = {
  bg: 'transparent',
  color: 'contrast.40',
};
const warning: BadgeVariant = {
  bg: 'warning.05',
  color: 'contrast.70',
};
const error: BadgeVariant = {
  bg: 'error.05',
  color: 'error.70',
};
const info: BadgeVariant = {
  bg: 'primary.05',
  color: 'primary.70',
};
const success: BadgeVariant = {
  bg: 'green.05',
  color: 'green.70',
};

const variants: BadgeVariants = {
  fallback,
  warning,
  error,
  info,
  success,
};

export default variants;
