import styled from 'styled-components';

const ToggleIcon = styled.div`
  display: flex;
  border-radius: 100%;
  cursor: pointer;
  margin-left: auto;
  padding: 0.25rem;

  svg {
    stroke: #fff;
  }
`;

export default ToggleIcon;
