import React, { useState, ReactNode } from 'react';

import AlertBox from './base';
import AlertTitle from './title';
import Box from '../box';
import AlertText from './text';

import {
  WaitingIcon,
  InfoIcon,
  SuccessIcon,
  ErrorIcon,
  CloseIcon,
  OpenIcon,
} from '../icon';

import Toggle from './toggle';

// FIXME: Move this icon map somewhere else.
const icons = {
  warning: WaitingIcon,
  info: InfoIcon,
  success: SuccessIcon,
  error: ErrorIcon,
  default: InfoIcon,
};

interface AlertProps {
  text?: ReactNode;
  title?: ReactNode;
  variant?: string;
  collapsible?: boolean;
  onClick?: Function;
  initialOpen?: boolean;
  displayIcon?: boolean;
}

/**
 * Will display a <Alert /> component.
 * @param props
 */
function Alert(props: AlertProps): JSX.Element {
  const {
    text,
    title,
    variant,
    collapsible = false,
    onClick,
    initialOpen = true,
    displayIcon = true,
    ...rest
  } = props;

  const [isOpen, toggle] = useState(initialOpen);

  const AlertIcon = icons[variant || 'default'];

  const handleOnClick = () => {
    if (collapsible) {
      toggle(!isOpen);
    }
    if (onClick && typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <AlertBox variant={variant} {...rest}>
      {title && (
        <AlertTitle>
          {AlertIcon && displayIcon && (
            <Box width={25} height={25} mr="full">
              <AlertIcon />
            </Box>
          )}
          {title}
          {collapsible && (
            <Toggle onClick={handleOnClick}>
              {isOpen ? <CloseIcon /> : <OpenIcon />}
            </Toggle>
          )}
        </AlertTitle>
      )}

      {text && isOpen && (
        <AlertText
          borderTop={title ? '1px solid' : 'none'}
          mt={title ? 'half' : 0}
          pt="half"
          pb={`${!title && 'half'}`}
        >
          {text}
        </AlertText>
      )}
    </AlertBox>
  );
}

export default Alert;

export { AlertBox, AlertTitle };
