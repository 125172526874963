import {
  compose,
  flexBasis,
  FlexBasisProps,
  flexDirection,
  FlexDirectionProps,
  flexWrap,
  FlexWrapProps,
  alignContent,
  AlignContentProps,
  alignItems,
  AlignItemsProps,
  justifyContent,
  JustifyContentProps,
  justifyItems,
  JustifyItemsProps,
  order,
  OrderProps,
} from 'styled-system';

const FLEX_CONTAINER = compose(
  flexBasis,
  flexDirection,
  flexWrap,
  alignContent,
  alignItems,
  justifyContent,
  justifyItems,
  order
);

export interface FlexContainerStyle
  extends FlexBasisProps,
    FlexDirectionProps,
    FlexWrapProps,
    AlignContentProps,
    AlignItemsProps,
    JustifyContentProps,
    JustifyItemsProps,
    OrderProps {}

export default FLEX_CONTAINER;
