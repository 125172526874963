import styled from 'styled-components';
import ButtonBase from '../../button';
import { ButtonProps } from '../../button/base';

const Submit = styled(ButtonBase)<ButtonProps>``;

Submit.defaultProps = {
  as: 'input',
  type: 'submit',
  variant: 'primary',
  value: 'Submit',
};

export default Submit;
