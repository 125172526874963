import styled from 'styled-components';
import { display, DisplayProps, overflow, OverflowProps } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

import LAYOUT, { LayoutStyle } from '../styleProps/layout';
import COMMON, { CommonStyle } from '../styleProps/common';
import POSITION, { PositionStyle } from '../styleProps/position';
import BORDER, { BorderStyle } from '../styleProps/border';
import BACKGROUND, { BackgroundStyle } from '../styleProps/background';

interface AlertTextProps
  extends LayoutStyle,
    CommonStyle,
    PositionStyle,
    BorderStyle,
    BackgroundStyle,
    DisplayProps,
    OverflowProps {
  cursor?: string;
}

const AlertText = styled.div<AlertTextProps>`
  box-sizing: border-box;
  display:block;
  line-height: ${p => themeGet('lineHeights.base', '1.5')(p)};
  cursor: ${p => p.cursor || 'default'};

  ${COMMON}
  ${LAYOUT}
  ${POSITION}
  ${display}
  ${overflow}
  ${BORDER}
  ${BACKGROUND}
`;

AlertText.defaultProps = {
  width: 'auto',
};

export default AlertText;
