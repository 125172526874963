import React from 'react';

function SvgComponent(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M24 24H0V0h24z"
        className="prefix__cls-1"
        data-name="Rectangle 811"
      />
      <path
        d="M19.29 14.522l-6.713-6.71a.743.743 0 00-.055-.081.757.757 0 00-1.044 0 .738.738 0 00-.055.081l-6.713 6.71a.72.72 0 001.018 1.018L12 9.268l6.273 6.272a.72.72 0 101.017-1.018z"
        className="prefix__cls-2"
        data-name="Path 1850"
      />
    </svg>
  );
}

export default SvgComponent;
