import React from 'react';
import { FlexItem } from '../flex';

function HeaderActions({ actions }) {
  if (!actions) {
    return null;
  }

  return (
    <FlexItem ml="auto" width="auto">
      {actions}
    </FlexItem>
  );
}

export default HeaderActions;
