import styled from 'styled-components';

import { H6, HeadingProps } from '../typography/headings';

import LAYOUT, { LayoutStyle } from '../styleProps/layout';
import FLEX_CONTAINER, {
  FlexContainerStyle,
} from '../styleProps/flexContainer';

interface AlertTitleProps
  extends LayoutStyle,
    FlexContainerStyle,
    HeadingProps {}

const AlertTitle = styled(H6)<AlertTitleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;

  width: 100%;

  ${LAYOUT}
  ${FLEX_CONTAINER}
`;

export default AlertTitle;
