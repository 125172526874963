const contrast = {
  '90': '#1f1f1f',
  '80': '#3f3f3f',
  '70': '#5e5e5e',
  '60': '#727272',
  '50': '#9b9b9b',
  '40': '#bababa',
  '30': '#dddddd',
  '20': '#ececec',
  '10': '#f4f4f4',
  '05': '#fafafa',
};

const green = {
  '90': '#007a12',
  '80': '#009b28',
  '70': '#00ad35',
  '60': '#00c042',
  '50': '#00d14d',
  '40': '#2bd96a',
  '30': '#65e188',
  '20': '#98e9ac',
  '10': '#c2f2cc',
  '05': '#e6faea',
};

const primary = {
  '90': '#005c8a',
  '80': '#007cab',
  '70': '#008dbe',
  '60': '#00a0d0',
  '50': '#10adde',
  '40': '#36b9e0',
  '30': '#58c6e3',
  '20': '#84d6ea',
  '10': '#b4e6f2',
  '05': '#e1f6fa',
};

const success = {
  '90': '#007a12',
  '80': '#009b28',
  '70': '#00ad35',
  '60': '#00c042',
  '50': '#00d14d',
  '40': '#2bd96a',
  '30': '#65e188',
  '20': '#98e9ac',
  '10': '#c2f2cc',
  '05': '#e6faea',
};

const error = {
  '90': '#c00004',
  '80': '#ce0815',
  '70': '#db171d',
  '60': '#ed2623',
  '50': '#fb3322',
  '40': '#f74742',
  '30': '#ed6c69',
  '20': '#f69693',
  '10': '#ffcbcf',
  '05': '#ffeaed',
};

const warning = {
  '90': '#fd7100',
  '80': '#fd9100',
  '70': '#fca200',
  '60': '#fbb500',
  '50': '#fbc300',
  '40': '#fbcc22',
  '30': '#fbd74d',
  '20': '#fce181',
  '10': '#fdedb3',
  '05': '#fef8e1',
};

const colors = {
  body: '#f4f4f4',
  white: '#fff',
  text: contrast['90'],
  transparent: 'transparent',
  brand: {
    primary: primary['60'],
  },
  links: primary['60'],
  primary,
  contrast,
  success,
  error,
  warning,
  green,
};

export default colors;
