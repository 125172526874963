import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { FlexItem, Flex } from '../flex';

const InputGroupItem = styled(FlexItem)`
  width: auto;
  background: ${themeGet('colors.contrast.10')};
  border: 1px solid ${themeGet('colors.contrast.40')};

  align-items: center;
  justify-content: center;
  white-space: nowrap;

  padding: ${themeGet('forms.padding.x')} ${themeGet('forms.padding.y')};
`;

const InputGroup = styled(Flex)`
  > :not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > :not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  > :first-child {
    border-top-left-radius: ${themeGet('radii.default')};
    border-bottom-left-radius: ${themeGet('radii.default')};
  }

  > :last-child {
    border-top-right-radius: ${themeGet('radii.default')};
    border-bottom-right-radius: ${themeGet('radii.default')};
  }

  ${InputGroupItem}:not(:first-child) {
    border-left: none;
  }

  ${InputGroupItem}:not(:last-child) {
    border-right: none;
  }
`;

export { InputGroup, InputGroupItem };
