import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';

import COMMON, { CommonStyle } from '../styleProps/common';

interface OverlineProps extends CommonStyle {
  uppercase?: boolean;
  block?: boolean;
}

const Overline = styled('span')<OverlineProps>`
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  display: ${({ block }) => (block ? 'block' : 'inline-block')};
  font-size: 90%;
  letter-spacing: 0.5px;
  line-height: 14px;
  font-weight: 400;
  color: ${p => themeGet(p.color || 'colors.contrast.50')(p)};

  ${COMMON}
`;

Overline.defaultProps = {
  mb: 'half',
  uppercase: true,
};

export default Overline;
