import { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import COMMON, { CommonStyle } from '../../styleProps/common';
import BORDER, { BorderStyle } from '../../styleProps/border';
import LAYOUT, { LayoutStyle } from '../../styleProps/layout';

import getVariant from './variants';

export interface InputBaseProps extends CommonStyle, BorderStyle, LayoutStyle {}

function makeVariant(props) {
  const { valid = true, variant = 'default', ...rest } = props;

  const data = {
    variant: !valid ? 'error' : variant,
    valid,
    ...rest,
  };

  return getVariant(data);
}

// prettier-ignore
const inputBase = css<InputBaseProps>`
  display: block;
  width: 100%;
  height: calc(${themeGet("lineHeights.base")}rem + ${themeGet("forms.padding.y")} + (${themeGet("borders.borderWidths.default")} * 2));
  padding: ${themeGet("forms.padding.x")} ${themeGet("forms.padding.y")};
  line-height: ${themeGet("lineHeights.base")};

  background-clip: padding-box;
  
  border-radius: ${themeGet("radii.default")};

  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

  box-shadow: none;

  // Unstyle the caret on <select> in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  :focus {
    outline: none;
    z-index: 2;
  }

  ${p => makeVariant(p)}

  ${COMMON}
  ${BORDER}
  ${LAYOUT}
`;

export default inputBase;
